import { ModelCommonCodeFilterInput } from "@/API";
import { graphqlFetcher } from "@/assets/ts/queryClient";
import { listCommonCodeBySortValue } from "@/graphql/queries";

type SortProps = {
  Key: string;
  OrderBy: "ASC" | "DESC";
};

type TGetAllQuery = <T>(
  listQuery: any,
  data: any
) => Promise<{ data: T }>;

class Utils {
  constructor() { }
  slowCall(ms: number) {
    return new Promise(async (cb: any) => setTimeout(await cb, ms));
  }

  makeUniqueKey(prefix: string) {
    return prefix + Math.floor(Math.random() * 99999999 + 1);
  }

  listSort(data: any, sortprops: Array<SortProps>) {
    // 더이상 정렬을 하지 않거나 데이터 1개만 비교하는 경우 그대로 반환
    if (sortprops?.length === 0 || data?.length == 1 || !data) {
      return data;
    }

    // 정렬기준 1개씩 꺼내기
    const sort = sortprops.shift();

    // 잘못된 정렬기준이 들어있는 경우 그대로 반환
    if (!sort?.Key || !sort?.OrderBy) {
      return data;
    }

    // 정렬 실행
    let sortData: any = [];
    let tempResult: any = [];
    if (sort?.OrderBy == "DESC") {
      tempResult = data.sort((a: any, b: any) =>
        a[sort.Key] > b[sort.Key] ? -1 : 1
      );
    } else {
      tempResult = data.sort((a: any, b: any) =>
        a[sort.Key] < b[sort.Key] ? -1 : 1
      );
    }

    // 동일한 정렬값에 대해서 그룹화
    let tempResult2: any = {};
    tempResult.map((val: any, index: number) => {
      if (!tempResult2[val[sort.Key]]) {
        tempResult2[val[sort.Key]] = [];
      }
      tempResult2[val[sort.Key]].push(val);
    });

    // 재귀함수를 이용한 그룹화된 재정렬
    for (let k in tempResult2) {
      this.listSort(tempResult2[k], JSON.parse(JSON.stringify(sortprops))).map(
        (val: any, index: number) => {
          sortData.push(val);
        }
      );
    }

    return sortData;
  }

  async getCommonCode(
    siteId: string,
    pattern: string,
    parentId?: string,
    isStructure = false
  ) {
    // let filterInput: ModelCommonCodeFilterInput = {};

    let filter: any = {
      _deleted: { ne: true },
      isUse: { eq: true },
      pattern: { eq: pattern },
    };
  
    if (parentId !== undefined) {
      filter.parentId = { eq: parentId };
    }

    let result: any = await this.getAllQuery(
      listCommonCodeBySortValue,
      {
        
        sitesID:  siteId ,
        sortDirection: "ASC",
        filter: {
          ...filter
        },
        limit: 2000,
      }
    );

    if (!result.data) {
      return [];
    }

    const data: any = result.data.listCommonCodeBySortValue.items;

    if (isStructure === true && data.length) {
      let struc: any = {};
      data.map((v: any, i: number) => {
        struc[v.value] = v.name;
      });
      return struc;
    }

    return data;
  }

  getAllQuery: TGetAllQuery = async (listQuery: any, data: any) => {
    let queryData: any = [];
    let queryKey: string = '';
    let r: any = await graphqlFetcher(listQuery, data);
    if (queryKey == '' && Object.keys(r?.data)) {
      queryKey = Object.keys(r?.data)[0];
    }
    queryData = [
      ...queryData,
      ...r?.data[queryKey]?.items
    ];
    if (r?.data[queryKey]?.nextToken && data?.from == undefined && (data?.form > 0 || data?.limit > 100 || data?.limit == undefined)) {
      let nt = r?.data[queryKey].nextToken;
      let check = true;
      while (check) {
        let rr: any = await graphqlFetcher(listQuery, {
          ...data,
          nextToken: nt,
        });

        queryData = [
          ...queryData,
          ...rr?.data[queryKey].items
        ];

        if (rr?.data[queryKey].nextToken) {
          nt = rr?.data[queryKey].nextToken;
        } else {
          check = false;
        }
      }
    }

    return {
      data: {
        [queryKey]: {
          items: queryData
        }
      } as any
    }
  }

  transUpdateType(data: any, deleteCreatedAt = false) {
    delete data._lastChangedAt;
    delete data._deleted;
    delete data.__typename;
    delete data.updatedAt;
    if (deleteCreatedAt) {
      delete data.createdAt;
    }
    return data;
  }

  formatNumberWithCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getUniqueKey() {
    return (
      btoa(
        String(new Date().getTime() + new Date().getUTCMilliseconds())
      ).substring(0, 10) + new Date().getUTCMilliseconds()
    );
  }

  formatTelHyphen(tel: string) {
    return tel.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

const utils = new Utils();

export default utils;
