import { graphqlFetcher } from "@/assets/ts/queryClient";

import { getBoard, searchBoards, searchFullServiceDisplayBoards, searchPopupBanners } from "@/graphql/customQueries";
import {
  createBoard,
  createComment,
  deleteBoard,
  deleteComment,
  updateBoard,
  updateComment,
} from "@/graphql/mutations";
import {
  searchBanners,
  listMenusBySortValue,
  listQuickMenuBySortValue,
  searchDDaySchedules,
  listCommonCodeBySortValue
} from "@/graphql/queries";

import {
  CreateBoardInput,
  CreateCommentInput,
  UpdateBoardInput,
  UpdateCommentInput,
} from "@/API";
import { APIReturnType } from "@/interface/api";
import {
  ListMenusBySortValue,
  ListQuickMenuBySortValue,
  TGetBoard,
  TSearchBoards,
  DDayItem,
  TSearchPopupBanners,
  THackersGate,
  TCommonCode,
  TSearchDisplayBoardItem
} from "@/interface/common";
import { TSearchBanners } from "@/interface/banner";

import ApiClient from "@/lib/ApiClient";
import awsApi from "@/lib/AwsAPI";
import utils from "@/lib/Utils";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { removeBase64Image } from "@/assets/ts/Utils";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");
dayjs.locale("ko");

export const fetchMenus = async (filter?: any) => {
  const result = await graphqlFetcher<{
    listMenusBySortValue: ListMenusBySortValue;
  }>(listMenusBySortValue, {
    sitesID: process.env.NEXT_PUBLIC_SITEID,
    filter: {
      isUse: { eq: true },
      _deleted: { ne: true },
    },
    sortDirection: "ASC",
  });

  return result.data?.listMenusBySortValue;
};

export const fetchBanners = async (filter: any, _sort?: any) => {
  let _s = [];

  if (!Array.isArray(_sort ?? {})) {
    if (Object.keys(_sort ?? {}).length > 0) {
      _s.push(_sort);
    } else {
      _s.push({ field: "sortValue", direction: "asc" });
      _s.push({ field: "createdAt", direction: "asc" })
    }
  } else {
    _s = _sort;
  }

  const result = await graphqlFetcher<{
    searchBanners: TSearchBanners;
  }>(searchBanners, {
    filter: {
      ...filter,
      sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
      _deleted: { ne: true },
      bannerClassCodeDepth1: { eq: "감정평가사" },
      bannerClassCodeDepth2: { eq: "PC" },
      and: [
        { isUse: { eq: true } },
        {
          displayBeginDateTime: {
            lte: parseInt(
              String(
                new Date(dayjs().utc().format("YYYY-MM-DDTHH:mm:ss") + ".000Z").getTime() / 100000
              )
            ),
          },
        },
        {
          displayEndDateTime: {
            gte: parseInt(
              String(
                new Date(dayjs().utc().format("YYYY-MM-DDTHH:mm:ss") + ".000Z").getTime() / 100000
              )
            ),
          },
        },
      ],
    },
    sort: _s,
  });

  return result.data?.searchBanners;
};

export const fetchSearchBoards = async (
  _from: number,
  _limit: number,
  _typeCode: string,
  _filter?: any,
  _sort?: any
) => {
  const result = await graphqlFetcher<{ searchBoards: TSearchBoards }>(
    searchBoards,
    {
      filter: {
        ..._filter,
        _deleted: { ne: true },
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
        typeCode: { eq: _typeCode },
        isDisplay: { eq: true },
      },
      from: _from,
      limit: _limit,
      sort: [_sort ? _sort : { field: "createdAt", direction: "desc" }],
    }
  );

  return result?.data?.searchBoards;
};

/**어드민용 */
export const fetchSearchBoardsAdmin = async (
  _from: number,
  _limit: number,
  _typeCode: string,
  _filter?: any,
  _sort?: any,
  _adminCk?: any
) => {
  const result = await graphqlFetcher<{ searchBoards: TSearchBoards }>(
    searchBoards,
    {
      from: _from,
      limit: _limit,
      sort: [_sort ? _sort : { field: "createdAt", direction: "desc" }],
      filter: _adminCk
        ? {
          ..._filter,
          _deleted: { ne: true },
          sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
          typeCode: { eq: _typeCode },
        }
        : {
          ..._filter,
          _deleted: { ne: true },
          sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
          typeCode: { eq: _typeCode },
          isDisplay: { eq: true },
        },
    }
  );

  return result?.data?.searchBoards;
};

export const fetchGetBoard = async (_id: string) => {
  try {
    const api = new ApiClient({
      baseUrl: `${window.location.origin}/admin/api`,
      contentType: "application/json; charset=utf-8",
    });
    const { data } = await api.get<APIReturnType<TGetBoard>>(
      `/board?id=${_id}`
    );

    return data.data || {} as TGetBoard;
  } catch (error) {
    console.log("*** fetch Error ***");
    console.log(error);

    return {
      id: "1",
      title: "error",
      categoryCode: "error",
      regMemberName: "error",
      regMemberIdx: 0,
      createAt: "2023-09-11",
      viewCount: 0,
      content: String(error),
    } as unknown as TGetBoard;
  }
};

// 조회수 X
export const fetchBoardDetail = async (id: string) => {
  const result = await graphqlFetcher<{ getBoard: TGetBoard }>(getBoard, {
    id,
  });

  return result.data?.getBoard;
};

export const fetchDeleteBoard = async (id: string, _version: number) => {
  const result = await graphqlFetcher<{ deleteBoard: TGetBoard }>(deleteBoard, {
    input: { id, _version },
  });

  return result.data?.deleteBoard;
};

export const fetchCreateBoard = async (
  params: Omit<CreateBoardInput, "_version" | "sitesID">
) => {
  try {
    const { content, ...other } = params;

    const removeBase64Content = await removeBase64Image(content || "");

    if (!removeBase64Content) {
      throw new Error("removeBase64Content is empty");
    }

    // 1. 큰 용량 대응 위해 빈 데이터로 먼저 생성
    const result = await graphqlFetcher<{ createBoard: TGetBoard }>(createBoard, {
      input: {
        ...other,
        content: "",
        sitesID: process.env.NEXT_PUBLIC_SITEID,
      },
    });

    const api = new ApiClient({
      baseUrl: "/admin/api",
      contentType: "application/json; charset=utf-8",
    });

    // 2. 빈 데이터 + 컨텐츠 내용
    const dynamodbResponse = await api.post(
      "/aws/setLargeDataS3",
      {
        data: removeBase64Content,
        siteId: process.env.NEXT_PUBLIC_SITEID || "",
        id: result.data?.createBoard.id || "",
        table: "Board",
        column: "content"
      }
    );

    if (dynamodbResponse.status !== 200) {
      throw new Error(dynamodbResponse.data);
    }

    return result.data?.createBoard;

  } catch (error) {
    return {
      id: "-999",
      title: "create error",
      categoryCode: "create error",
      regMemberName: "create error",
      regMemberIdx: 0,
      createAt: new Date(),
      viewCount: 0,
      content: String(error),
    } as unknown as TGetBoard;
  }
};

export const fetchUpdateBoard = async (
  params: UpdateBoardInput & Pick<TGetBoard, "comments">
) => {
  try {
    delete params.comments;

    const { content, ...other } = params;

    const removeBase64Content = await removeBase64Image(content || "");

    if (!removeBase64Content) {
      throw new Error("removeBase64Content is empty");
    }

    const api = new ApiClient({
      baseUrl: "/admin/api",
      contentType: "application/json; charset=utf-8",
    });

    const result = await graphqlFetcher<{ updateBoard: TGetBoard }>(updateBoard, {
      input: other,
    });

    await awsApi.sleep(1200);

    const s3Response = await api.post(
      "/aws/setLargeDataS3",
      {
        data: removeBase64Content,
        siteId: process.env.NEXT_PUBLIC_SITEID || "",
        id: other.id,
        table: "Board",
        column: "content",
        isUpdate: true
      }
    );

    if (s3Response.status !== 200) {
      throw new Error(s3Response.data);
    }

    return result.data?.updateBoard;

  } catch (error) {
    return {
      id: "999",
      title: "update error",
      categoryCode: "update error",
      regMemberName: "update error",
      regMemberIdx: 0,
      createAt: new Date(),
      viewCount: 0,
      content: String(error),
    } as unknown as TGetBoard;
  }
};

export const fetchDeleteBoardReply = async (id: string, _version: number) => {
  const result = await graphqlFetcher<{ deleteComment: TGetBoard }>(
    deleteComment,
    { input: { id, _version } }
  );

  return result.data?.deleteComment;
};

export const fetchCreateBoardReply = async (
  params: Omit<CreateCommentInput, "_version" | "sitesID">
) => {
  const result = await graphqlFetcher<{ createComment: TGetBoard }>(
    createComment,
    {
      input: {
        ...params,
        sitesID: process.env.NEXT_PUBLIC_SITEID,
      },
    }
  );

  return result.data?.createComment;
};

export const fetchUpdateBoardReply = async (
  params: UpdateCommentInput & Pick<TGetBoard, "comments">
) => {
  // delete params.comments;

  const result = await graphqlFetcher<{ updateComment: TGetBoard }>(
    updateComment,
    { input: params }
  );

  return result.data?.updateComment;
};

export const fetchQuickMenu = async (filter: any) => {
  const result = await graphqlFetcher<{
    listQuickMenuBySortValue: ListQuickMenuBySortValue;
  }>(listQuickMenuBySortValue, {
    filter: {
      ...filter,
      _deleted: { ne: true },
      isUse: { eq: true },
    },
    sortDirection: "ASC",
    sitesID: process.env.NEXT_PUBLIC_SITEID,
  });

  return result.data?.listQuickMenuBySortValue;
};

export const fetchDDay = async (filter: any) => {
  const result = await graphqlFetcher<{
    searchDDaySchedules: DDayItem;
  }>(searchDDaySchedules, {
    filter: {
      ...filter,
      sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
      isUse: { eq: true },
    },
    sort: [{ field: "createdAt", direction: "desc" }],
  });
  return result.data?.searchDDaySchedules;
};

export const fetchPopupBanners = async (
  _filter?: any,
  _sort?: { field: string, direction: "asc" | "desc" }[]
) => {
  const result = await graphqlFetcher<{ searchPopupBanners: TSearchPopupBanners }>(
    searchPopupBanners, {
    filter: {
      ..._filter,
      _deleted: { ne: true },
      sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
      isUse: { eq: true },
      isDisplayPC: { eq: true },
      and: [
        {
          displayBeginDateTime: {
            lte: parseInt(String(new Date(dayjs().utc().format("YYYY-MM-DDTHH:mm:ss") + ".000Z").getTime() / 100000)),
          },
        },
        {
          displayEndDateTime: {
            gte: parseInt(String(new Date(dayjs().utc().format("YYYY-MM-DDTHH:mm:ss") + ".000Z").getTime() / 100000)),
          },
        },
      ],
    },
    sort: [...(_sort || []), { field: "createdAt", direction: "desc" }],
  });

  return result.data?.searchPopupBanners.items;
}

export const fetchGate = async (): Promise<THackersGate> => {
  const api = new ApiClient();
  const { data } = await api.get<APIReturnType<THackersGate>>(
    `/integrationGate/pc/${process.env.NEXT_PUBLIC_SERVICE_ID}`
  );

  return data.data || { gateInfo: {}, gateCategory: [] } as unknown as THackersGate;
};

export const fetchGetCommonCode = async (
  pattern: string,
  filter?: any,
  isStructure?: boolean
) => {
  const result = await utils.getAllQuery<any>(listCommonCodeBySortValue, {
    sitesID: process.env.NEXT_PUBLIC_SITEID,
    filter: {
      _deleted: { ne: true },
      pattern: { eq: pattern },
      ...filter,
    },
  });

  if (!result.data) {
    return [];
  }

  const data: TCommonCode[] = result.data.listCommonCodeBySortValue.items;

  if (isStructure && data.length) {
    let struc: any = {};
    data.map((v: any) => {
      struc[v.value] = v.name;
    });

    return struc;
  }

  return data;
};

// 전광판
export const fetchDisplayBoards = async (displayBoardCode: string) => {
  const result = await graphqlFetcher<{ searchFullServiceDisplayBoards: { items: TSearchDisplayBoardItem[] } }>(
    searchFullServiceDisplayBoards,
    {
      filter: {
        _deleted: { ne: true },
        isUse: { eq: true },
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
        displayBoardCode: { matchPhrase: displayBoardCode },
      },
      sort: [{ field: "sortValue", direction: "asc" }],
    }
  );

  return result.data?.searchFullServiceDisplayBoards.items;
}