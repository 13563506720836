import ApiClient from "./ApiClient";

class AwsAPI {
  async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async setLargeDataS3(data: string, siteId: string, id: string, table: string, column: string) {
    try {
      const api = new ApiClient({
        baseUrl: "/api/aws",
        contentType: "application/json"
      });

      const result = await api.post(
        "/setLargeDataS3",
        { data, siteId, id, table, column }
      );

      return result;
    } catch (error) {
      return {
        status: 999,
        data: String(error)
      }
    }
  }
}

const awsApi = new AwsAPI();

export default awsApi;